import { graphql, useStaticQuery } from "gatsby"

export default function useSeoContent() {
  const {
    file: {
      config: {
        frontmatter: {
          sitename,
          meta_title_suffix,
          default_meta_title,
          default_meta_description,
          default_featured_image,
          home_meta_title,
          home_meta_description,
          work_meta_title,
          work_meta_description,
          work_featured_image,
          privacypolicy_meta_title,
          privacypolicy_meta_description,
        },
      },
    },
  } = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "data" }, name: { eq: "seo" }) {
        config: childMarkdownRemark {
          frontmatter {
            sitename
            meta_title_suffix
            default_meta_title
            default_meta_description
            default_featured_image {
              childImageSharp {
                fixed(width: 1200, height: 600, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            home_meta_title
            home_meta_description
            work_meta_title
            work_meta_description
            work_featured_image {
              childImageSharp {
                fixed(width: 1200, height: 600, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            privacypolicy_meta_title
            privacypolicy_meta_description
          }
        }
      }
    }
  `)
  return {
    sitename,
    meta_title_suffix,
    default_meta_title,
    default_meta_description,
    default_featured_image: default_featured_image.childImageSharp.fixed,
    home_meta_title,
    home_meta_description,
    work_meta_title,
    work_meta_description,
    work_featured_image: work_featured_image?.childImageSharp?.fixed,
    privacypolicy_meta_title,
    privacypolicy_meta_description,
  }
}
