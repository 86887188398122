import React from "react"
import { Helmet } from "react-helmet"
import { FixedObject } from "gatsby-image"

import { absUrl } from "../utils"
import useSeoContent from "../hooks/use-seo-content"

export interface SEOProps {
  location: any
  title?: string
  description?: string
  featuredImage?: FixedObject
  lang?: string
}

export const SEO = ({
  description,
  featuredImage,
  title,
  location,
}: SEOProps) => {
  const {
    meta_title_suffix,
    default_meta_title,
    default_meta_description,
    default_featured_image,
    sitename,
  } = useSeoContent()

  const metaTitle = title || default_meta_title
  const metaDescription = description || default_meta_description
  const metaFeaturedImage = (featuredImage || default_featured_image).src
  const metaAuthor = sitename
  const currentUrl = absUrl(location.pathname)

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={metaTitle}
      titleTemplate={`%s | ${meta_title_suffix}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          name: `canonical`,
          content: currentUrl,
        },
        {
          property: `og:url`,
          content: currentUrl,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: absUrl(metaFeaturedImage),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:image`,
          content: absUrl(metaFeaturedImage),
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  )
}

export default SEO
