const path = require("path")

const baseURL = process.env.URL

const slickSettings = (settings = {}) => ({
  dots: false,
  infinite: true,
  initialSlide: 0,
  speed: 500,
  variableWidth: true,
  arrows: true,
  swipeToSlide: true,
  centerMode: true,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  ...settings,
})

const absUrl = segment => {
  return path.join(baseURL || "", segment)
}

const toCategorySlug = category => {
  return `/category/${category}`.toLowerCase().replace(/\s/g, "-")
}

const makeChunks = (array, size) => {
  let result = []
  let i,
    j,
    chunk = size
  for (i = 0, j = array.length; i < j; i += chunk) {
    result.push(array.slice(i, i + chunk))
  }
  return result
}

const filenameToTitle = str => {
  return str.replace(/-/g, " ").replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

module.exports = {
  baseURL,
  filenameToTitle,
  slickSettings,
  absUrl,
  toCategorySlug,
  makeChunks,
}
